<template>
  <div class="chatbot">
    <div class="chatbot-container" :class="{ disabled: isCreatingChatbot }">
      <div class="chatbot-header" :style="getHeadingStyle">
        <div v-if="getImage" class="image-container">
          <span>
            <img :src="getImage" alt="chatbot-image" class="centered-image" />
          </span>
        </div>

        <div>
          <h2 class="chatbot-heading">
            {{ getHeading }}
          </h2>
          <p>{{ getSubHeading }}</p>
        </div>
      </div>

      <div class="chatbot-conversation" ref="chatbotConversation">
        <div class="model-selector">
          <select v-model="selectedModel" @change="onModelChange">
            <option value="gpt-4o">ChatGPT-4o</option>
            <option value="gpt-4o-mini">ChatGPT-4o mini</option>
            <option value="o1">ChatGPT-o1</option>
            <option value="claude-3-5-sonnet">Claude 3.5 Sonnet</option>
            <option value="claude-3-7-sonnet-20250219">
              Claude 3.7 Sonnet
            </option>
          </select>
        </div>
        <div
          class="chatbot-message"
          v-for="(message, index) in messages"
          :key="index"
          :class="{
            'user-message': message.is_user,
            'bot-message': !message.is_user,
          }"
        >
          <div v-if="message.is_user" :style="getUserMessageStyle">
            <strong>You: </strong>
            <template v-if="message.file">
              <div class="image-preview">
                <img
                  v-if="isImageFile(message.file)"
                  :src="getFilePreview(message.file)"
                  :alt="message.file.name"
                />
                <div v-else class="file-icon">
                  {{ getFileExtension(message.file) }}
                </div>
                <br />
                <span>{{ message.file.name }}</span>
              </div>
            </template>
            <template v-else>
              {{ message.text }}
            </template>
          </div>
          <div
            v-else
            :style="getBotMessageStyle"
            v-html="
              '<strong>' +
              getAssistantName +
              ':</strong>' +
              parseMarkdown(message.text)
            "
          ></div>
        </div>
      </div>
      <div class="input-container">
        <div class="system-prompt-label" @click="togglePromptCollapse">
          <span class="toggle-icon" :class="{ expanded: !isPromptCollapsed }"
            >▶</span
          >
          {{ getPromptLabel }}
        </div>
        <div v-if="!isPromptCollapsed" class="chatbot-input">
          <textarea
            :placeholder="getPromptMessage"
            v-model="promptValue"
            @input="autoResize"
          ></textarea>
          <CButton
            class="d-flex justify-content-center"
            :style="getSendButtonStyle"
            @click="setDefaultPrompt"
          >
            <CIcon :icon="cilReload" />
          </CButton>
        </div>
      </div>
      <div class="input-container">
        <div class="chatbot-input">
          <div
            class="parent-div"
            @dragover.prevent
            @dragenter.prevent="handleDragOver"
            @drop.prevent="handleDrop"
          >
            <div class="file-preview-area" v-if="attachedFiles.length > 0">
              <div class="file-preview-scroll">
                <div
                  v-for="(file, index) in attachedFiles"
                  :key="index"
                  class="file-preview"
                >
                  <img
                    class="file-icon"
                    v-if="isImageFile(file)"
                    :src="getFilePreview(file)"
                    :alt="file.name"
                  />
                  <div v-else class="file-icon">
                    {{ getFileExtension(file) }}
                  </div>
                  <button class="remove-file-btn" @click="removeFile(index)">
                    <CIcon :icon="cilX" />
                  </button>
                </div>
              </div>
            </div>
            <div class="message-area" ref="messageArea">
              <textarea
                v-model="userInput"
                @keydown.enter.prevent="sendMessage"
                :placeholder="getInputMessage"
                @input="autoResize"
              ></textarea>
            </div>
          </div>
          <input
            type="file"
            ref="fileInput"
            style="display: none"
            @change="handleFileUpload"
            multiple
          />
          <div class="button-container">
            <CButton
              @click="openFileBrowser"
              class="d-flex justify-content-center"
              :style="getAttachButtonStyle"
            >
              <CIcon :icon="cilPaperclip" />
            </CButton>
            <CButton
              @click="sendMessage"
              class="d-flex justify-content-center"
              :style="getSendButtonStyle"
            >
              <CIcon :icon="cilSend" />
            </CButton>
          </div>
        </div>
      </div>
    </div>
    <!-- Overlay for processing message -->
    <div
      v-if="isCreatingChatbot || isUpdatingChatbot"
      class="processing-overlay"
    >
      <div class="processing-message">
        <div class="loading-icon-wrapper">
          <img
            src="../../../public/images/loading.gif"
            alt="Processing..."
            class="loading-icon"
          />
        </div>
        <div v-if="isCreatingChatbot">Creating chatbot! Please wait!</div>
        <div v-else-if="isUpdatingChatbot">Updating chatbot! Please wait!</div>
      </div>
    </div>
    <ErrorPopup
      :show="showErrorPopup"
      :message="errorMessage"
      @close="closeErrorPopup"
    />
  </div>
</template>

<script>
import HTTPService from '@/services/HTTPService.js'
import StorageService from '../../services/StorageService.js'
import ErrorPopup from '@/components/ErrorPopup.vue'
import { v4 as uuidv4 } from 'uuid'
import { cilSend, cilPaperclip, cilReload, cilX } from '@coreui/icons'
import { marked } from 'marked'
import DOMPurify from 'isomorphic-dompurify'
// import hljs from 'highlight.js'

export default {
  components: {
    ErrorPopup,
  },
  props: {
    // bot_id: {
    //   type: String,
    //   required: true,
    // },
    conversation_id: {
      type: String,
      required: false,
    },
    style: {
      type: Object,
      default: () => ({
        botBackgroundColor: '#fff',
        botTextColor: 'black',
        chatbotHeading: 'Hello!',
        chatbotSubHeading: 'Ask me anything',
        firstMessage: 'Hello, what can I help you with?',
        headerBackgroundColor: '#001d61ff',
        headerTextColor: '#FFFFFF',
        image: null,
        inputPlaceholderText: 'What do these say?',
        promptDefaultText:
          'あなたはチャットでユーザを支援するAIアシスタントです。',
        promptPlaceholderText: 'Enter your prompt here.',
        promptLabelText: 'システムコンテキスト',
        sendButtonBackgroundColor: '#001d61ff',
        userBackgroundColor: '#001d61ff',
        userTextColor: '#FFFFFF',
        assistantName: 'Assistant',
        bot_id: 'preview',
      }),
    },
    name: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      messages: [],
      userInput: '',
      messageIndex: 0,
      conversationId: '',
      cilSend,
      cilPaperclip,
      cilReload,
      cilX,
      user: null,
      selectedModel: 'gpt-4o', // Default model
      attachedFiles: [],
      promptValue: this.style.promptDefaultText,
      isPromptCollapsed: false, // Stop collapsed by default
      bot_id: '',
      botName: 'POC Main Chat',
      isCreatingChatbot: false,
      isUpdatingChatbot: false,
      ragResult: [],
      showErrorPopup: false,
      errorMessage: '',
    }
  },
  async mounted() {
    console.log('loading..' + this.bot_id)
    try {
      console.log('get user..')

      const response = await HTTPService.getuser()
      this.user = response.data.user
      if (!this.user) {
        // If the user is not authenticated, push them to the login route
        this.$router.push('/login')
      }
    } catch (error) {
      console.error(error)
      // If there's an error or no user found, redirect to the login page
      this.$router.push('/login')
    }

    marked.setOptions({
      silent: true,
      xhtml: true,
      breaks: true,
      gfm: true,
    })

    this.messages.push({
      text: this.style.firstMessage,
      is_user: false,
    })
    this.messageIndex = 1
    if (this.conversation_id != '') {
      this.conversationId = this.conversation_id
    }

    if (this.$route.query.conversationId) {
      this.conversationId = this.$route.query.conversationId
    }

    if (!this.conversationId) {
      this.conversationId = uuidv4()
    } else {
      this.startConversation()
    }

    document.addEventListener('click', this.handleFileClick)
  },
  computed: {
    getImage() {
      return this.style.image
    },

    getAssistantName() {
      return this.style.assistantName
    },
    getHeading() {
      return this.style.chatbotHeading
    },
    getSubHeading() {
      return this.style.chatbotSubHeading
    },
    getHeadingStyle() {
      return {
        backgroundColor: this.style.headerBackgroundColor,
        color: this.style.headerTextColor,
      }
    },
    getSendButtonStyle() {
      const backgroundColor = this.style.sendButtonBackgroundColor

      return {
        backgroundColor,
        border: 'none',
        padding: '10px',
        marginRight: '10px',
      }
    },
    getInputMessage() {
      return this.style.inputPlaceholderText
    },
    getPromptMessage() {
      return this.style.promptPlaceholderText
    },
    getPromptLabel() {
      return this.style.promptLabelText
    },
    getUserMessageStyle() {
      return {
        backgroundColor: this.style.userBackgroundColor,
        color: this.style.userTextColor,
      }
    },
    getBotMessageStyle() {
      return {
        backgroundColor: this.style.botBackgroundColor,
        color: this.style.botTextColor,
      }
    },
    getAttachButtonStyle() {
      const backgroundColor = this.style.sendButtonBackgroundColor

      return {
        backgroundColor,
        border: 'none',
        padding: '10px',
        marginRight: '5px', // Margin between the buttons
      }
    },
    onModelChange() {
      console.log('Selected model:', this.selectedModel)
      return null
    },
    getDefaultPrompt() {
      return this.style.promptDefaultText
    },
  },
  methods: {
    handleFileClick(event) {
      const link = event.target.closest('a[data-filename]')
      if (link) {
        event.preventDefault() // Prevent the default action
        const filename = link.getAttribute('data-filename')
        this.handleFileDownload(filename) // Call your download method
      }
    },
    handleFileDownload(filename) {
      console.log('Clicked', filename) // Debugging
      HTTPService.getfile(filename) // Your existing file download logic
    },

    togglePromptCollapse() {
      this.isPromptCollapsed = !this.isPromptCollapsed
    },
    parseMarkdown(text) {
      if (typeof text !== 'string') {
        return '' // Return the text as is if it's not a string
      }

      text = text.replaceAll('\\n', '\n')
      text = text.replaceAll('```markdown', '```')
      try {
        const renderer = new marked.Renderer()
        renderer.link = function (href, title, text) {
          // Renderer() seems to return different parameters depending on the version of marked installed...
          const link = {
            href: href.href ?? href,
            text: href.text ?? text,
          }
          if (link.href.startsWith('/files/')) {
            // Extract the filename from the path (e.g., "/files/<filename>")
            const filename = link.href.split('/').pop()
            return `<a href="#" data-filename="${filename}">${link.text}</a>`
          } else {
            return `<a target="_blank" href="${link.href}">${link.text}</a>`
          }
        }

        text = text.replaceAll('```', '')
        let parsed = marked(text, { renderer: renderer })

        // format Bing's source links more nicely
        // 1. replace "[^1^]" with "[1]" (during progress streams)
        parsed = parsed.replace(/\[\^(\d+)\^]/g, '<strong>[$1]</strong>')
        // 2. replace "^1^" with "[1]" (after the progress stream is done)
        parsed = parsed.replace(/\^(\d+)\^/g, '<strong>[$1]</strong>')

        // Allow the iframe to show the images created by Bing Image Creator.
        return DOMPurify.sanitize(parsed, {
          ADD_TAGS: ['iframe'],
          ADD_ATTR: [
            'allow',
            'allowfullscreen',
            'frameborder',
            'scrolling',
            'srcdoc',
            'target',
          ],
        })
      } catch (err) {
        console.error('ERROR', err)
        return null
      }
    },
    async startConversation() {
      try {
        const response = await HTTPService.get_conversation(this.conversationId)
        for (const message of response.data) {
          const text = message.text
          const is_user = message.is_user
          this.messages.push({ text, is_user })
          // this.addMessage(message.text, message.is_user)
        }
      } catch (error) {
        console.log(error)
      }
    },

    autoResize() {
      const textarea = this.$refs.messageArea.querySelector('textarea')
      textarea.style.height = 'auto'
      textarea.style.height = textarea.scrollHeight + 'px'
    },

    openFileBrowser() {
      this.$refs.fileInput.click()
    },

    setDefaultPrompt() {
      this.promptValue = this.getDefaultPrompt
    },

    handleFileUpload(event) {
      const files = Array.from(event.target.files)
      this.attachedFiles.push(...files)
      this.$refs.fileInput.value = ''
    },

    handleDrop(event) {
      event.preventDefault()
      const files = Array.from(event.dataTransfer.files)
      this.attachedFiles.push(...files)
      this.$refs.fileInput.value = ''
    },

    handleDragOver(event) {
      event.preventDefault()
    },

    handleFiles(files) {
      this.attachedFiles.push(...files)
      this.$refs.fileInput.value = ''
      this.sendMessage()
    },

    removeFile(index) {
      this.attachedFiles.splice(index, 1)
    },

    isImageFile(file) {
      return file.type.startsWith('image/')
    },

    getFilePreview(file) {
      return URL.createObjectURL(file)
    },

    getFileExtension(file) {
      return file.name.split('.').pop().toUpperCase()
    },

    async sendMessage() {
      if (!this.userInput && this.attachedFiles.length === 0) return

      if (!this.bot_id) {
        // Create chatbot name
        if (this.attachedFiles.length > 0) {
          this.botName = `Uploaded ${this.attachedFiles[0].name}`
        } else {
          // Extract the first sentence or the first 20 characters, whichever is shorter
          const firstSentenceEnd =
            this.userInput.indexOf('.') !== -1
              ? this.userInput.indexOf('.') + 1
              : this.userInput.length
          const firstSentence = this.userInput.slice(0, firstSentenceEnd)

          // Limit to the first 20 characters if needed
          this.botName =
            firstSentence.length > 10
              ? firstSentence.slice(0, 20)
              : firstSentence
        }
        this.botName = '[POC] ' + this.botName
      }

      // 1. Create or update bot with current files
      if (!this.bot_id || this.attachedFiles.length > 0) {
        await this.createChatbot()

        for (const file of this.attachedFiles) {
          this.addMessage('', true, file)
        }
      }
      // Extract file names and encode them
      // const fileNames = this.attachedFiles.map((file) =>
      //   encodeURIComponent(file.name),
      // )

      // Join the file names into a single string
      // const filesParam = fileNames.join(',')
      // Clear the attached files
      this.attachedFiles = []

      if (this.userInput) {
        this.addMessage(this.userInput, true)
        this.messageIndex = this.messageIndex + 1
      }

      // 2. send message
      // URL encode user input and username to ensure they are safe to include in the URL
      // const encodedUserInput = encodeURIComponent(this.userInput)
      // const encodedUsername = encodeURIComponent(this.user.email)

      const url = `${process.env.VUE_APP_API_URL}/conversation`
      const body = {
        bot_id: this.bot_id,
        user_input: this.userInput,
        conversation_id: this.conversationId,
        username: this.user.email,
        prompt: this.promptValue,
        model: this.selectedModel,
        showRagResults: true,
        files: this.attachedFiles.map((file) => file.name),
      }
      this.userInput = ''
      try {
        let token = StorageService.getToken()
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(body),
        })

        if (!response.ok) {
          const errorBody = await response.json()
          this.errorMessage =
            errorBody.error ||
            `HTTP error! status: ${response.status} ${response.statusText}`
          this.showErrorPopup = true
          throw new Error(this.errorMessage)
        }

        const reader = response.body.getReader()
        const decoder = new TextDecoder()

        let buffer = ''
        let isProcessingJson = true

        // Initialize the message
        this.addMessage('', false)

        // eslint-disable-next-line no-constant-condition
        while (true) {
          const { done, value } = await reader.read()
          if (done) break

          buffer += decoder.decode(value, { stream: true })

          if (isProcessingJson) {
            const jsonEndIndex = buffer.indexOf('\r\n--frame\r\n')
            if (jsonEndIndex !== -1) {
              const jsonPart = buffer.slice(0, jsonEndIndex)
              const match = jsonPart.match(/\{[\s\S]*\}/)
              if (match) {
                this.ragResult = JSON.parse(match[0]).vdb_results
              }
              buffer = buffer.slice(jsonEndIndex + 8) // 8 is the length of '\r\n--frame'
              isProcessingJson = false
            }
          } else {
            const lines = buffer.split('\n')
            buffer = lines.pop() || '' // Keep the last incomplete line in the buffer

            for (const line of lines) {
              if (line.startsWith('data:')) {
                const data = line.slice(6)
                if (data === '[DONE]') {
                  this.messageIndex = this.messageIndex + 1
                  return // End of stream
                } else {
                  this.addMessage(data, false)
                }
              }
            }
          }
        }
      } catch (error) {
        console.error(error)
        if (error.response && error.response.data.error) {
          this.errorMessage = error.response.data.error
          this.showErrorPopup = true
        } else {
          this.errorMessage = 'Failed to create chatbot: ' + error.response
          this.showErrorPopup = true
        }
      }
    },

    cleanupObjectURLs() {
      this.messages.forEach((message) => {
        if (message.file && message.file.preview) {
          URL.revokeObjectURL(message.file.preview)
        }
      })
    },

    // Add this to clean up object URLs when the component is destroyed
    beforeDestroy() {
      this.cleanupObjectURLs()
    },

    async createChatbot() {
      this.showModal = false
      const formData = new FormData()
      if (this.attachedFiles) {
        for (let i = 0; i < this.attachedFiles.length; i++) {
          formData.append('files[]', this.attachedFiles[i])
        }
      }
      if (this.botName) {
        formData.append('data', JSON.stringify({ bot_name: this.botName }))
      }
      if (this.bot_id) {
        formData.append('bot_id', this.bot_id)
      }

      if (this.bot_id) this.isUpdatingChatbot = true
      else this.isCreatingChatbot = true
      await HTTPService.createChatbot(formData)
        .then((response) => {
          this.showConfirmation = true
          let bot_id = response.data.bot_id
          this.bot_id = bot_id
        })
        .catch((error) => {
          console.error(error)
          if (error.response && error.response.data.error) {
            this.errorMessage = error.response.data.error
            this.showErrorPopup = true
            if (
              error.response &&
              (error.response.status === 423 || error.response.status === 424)
            ) {
              this.planerror = true
            }
          } else {
            this.errorMessage = 'Failed to create chatbot: ' + error.response
            this.showErrorPopup = true
          }
        })
        .finally(() => {
          this.isCreatingChatbot = false
          this.isUpdatingChatbot = false
        })
    },

    addMessage(text, is_user, file = null) {
      if (this.messageIndex >= 0 && is_user === false) {
        // if first message then just push
        if (!this.messages[this.messageIndex]) {
          this.messages.push({
            text: '',
            is_user: is_user,
            file: null,
          })
        } else {
          this.messages[this.messageIndex].text += text
          this.messages[this.messageIndex].is_user = is_user
        }
      } else {
        this.messageIndex = this.messages.length

        if (file) {
          // If there's a file, create a message with the file preview
          this.messages.push({
            text: '',
            is_user: true,
            file: file,
          })
        } else {
          // For text messages
          this.messages.push({
            text,
            is_user,
            file: null,
          })
        }
        this.messageIndex = this.messages.length - 1
      }

      // Scroll to the bottom of the chatbot conversation
      this.$refs.chatbotConversation.scrollTop =
        this.$refs.chatbotConversation.scrollHeight
    },
    endConversation() {
      // this.$router.push('/chatbot-dashboard')
    },

    closeErrorPopup() {
      this.showErrorPopup = false
      this.errorMessage = ''
    },
  },
}
</script>

<style scoped>
.chatbot {
  height: 100%;
  width: 80%;
  margin: 0 auto;
}

.chatbot-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.chatbot-conversation {
  flex: 1;
  padding: 10px;
  overflow-y: scroll;
  overflow: auto;
  background-color: rgb(240, 245, 245);
}

.chatbot-message {
  margin: 10px 0;
}

.user-message {
  text-align: right;
}

.bot-message {
  text-align: left;
}

.chatbot-message div {
  display: inline-block;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  max-width: 80%;
}

.chatbot-message div:last-child {
  margin-bottom: 0;
}

.chatbot-message.user-message div {
  color: #fff;
}

.chatbot-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  margin: 10px 30px;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.drop-zone {
  border: 2px dashed #ccc;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  margin: 0px 30px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  height: 50%;
}

.drop-zone:hover {
  background-color: #f0f0f0;
}

input[type='text'] {
  flex: 1;
  margin-right: 10px;
  padding: 10px 15px;
  border-radius: 5px;
  border: 1px solid #ccc;
  line-height: normal;
  height: 50px;
}

button {
  padding: 12px 17px;
  border-radius: 5px;
  border: none;
  color: #fff;
}

button:hover {
  cursor: pointer;
  background-color: #0089b9 !important;
}

.chatbot-header {
  display: flex;
  flex-direction: row;
  background-color: rgb(173, 92, 92);
  width: 100%;
  /* height: 70px; */
  padding-top: 10px;
  padding-bottom: 10px;
  padding-inline: 10px;
  font-size: 1.25rem;
  margin-bottom: 0px;
}

.chatbot-heading {
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.2;
}

p {
  margin-bottom: 0px;
  font-size: 16px;
}
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
.centered-image {
  max-width: 40px;
  max-height: 40px;
}

.model-selector {
  position: sticky;
  top: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  padding: 10px 0;
  background-color: none; /* Or any color that matches your design */
}

.model-selector select {
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: white;
  font-size: 14px;
}

.message-area {
  width: 100%;
  transition: border 0.2s ease;
}

.message-area[draggable='true'] {
  cursor: move;
}

.message-area[draggable='true']:hover {
  border: 1px dashed #ccc;
}

.message-area textarea,
.chatbot-input textarea {
  width: 100%;
  resize: none;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 16px;
  max-height: 150px;
  overflow-y: auto;
  border: none;
}

textarea:focus,
input:focus {
  outline: none;
}

.file-preview-area {
  display: flex;
  width: 100%; /* Makes it take full width of parent */
  margin: 5px 5px 10px 5px;
  height: 130px;
  overflow-y: hidden;
  overflow-x: auto;
  align-items: center;
}

.file-preview-scroll {
  display: inline-flex; /* Changed to inline-flex */
  flex-wrap: nowrap;
}

.file-preview {
  flex: 0 0 auto; /* Prevents shrinking */
  margin-right: 10px;
  text-align: center;
  position: relative;
}

.file-preview img {
  max-width: 80px;
  max-height: 80px;
  object-fit: cover;
  border-radius: 5px;
}

.image-preview {
  display: inline-block;
  text-align: center;
  margin: 5px 0;
}

.image-preview img {
  max-width: 200px;
  max-height: 200px;
  object-fit: contain;
  border-radius: 5px;
}

.image-preview span {
  display: block;
  font-size: 0.8em;
  color: #fff;
  word-wrap: break-word;
  overflow-wrap: break-word;
  max-width: 200px;
}

.file-icon {
  width: 80px;
  height: 80px;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  border-radius: 5px;
  color: #000 !important;
}

.remove-file-btn {
  background-color: #333;
  color: white;
  border: none;
  padding: 0x;
  cursor: pointer;
  border-radius: 4px;
  margin-left: 10px;
  position: absolute;
  top: -6px;
  right: -6px;
  width: 20px; /* set the width */
  height: 20px; /* set the height to be the same as width */
  padding: 0; /* remove padding */
}

.remove-file-btn:hover {
  background-color: #ff1a1a;
}

.parent-div {
  display: flex;
  flex-direction: column; /* Đảm bảo các phần tử xếp chồng lên nhau */
  width: 95%; /* Điều chỉnh kích thước phù hợp */
}

.input-container {
  display: flex;
  flex-direction: column;
  background-color: #f3f5f7;
}

.system-prompt-label {
  font-weight: bold;
  margin: 0px 0px 0px 30px;
  font-size: 14px;
  cursor: pointer;
}

.toggle-icon {
  display: inline-block;
  margin-right: 5px;
  transition: transform 0.3s ease;
}

.toggle-icon.expanded {
  transform: rotate(90deg);
}

.chatbot-container.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.processing-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.processing-message {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.loading-icon-wrapper {
  margin-bottom: 10px;
}

.loading-icon {
  width: 50px;
  height: 50px;
}

.rag-results-table {
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.rag-results-table h3 {
  margin-bottom: 10px;
}

.rag-results-table table {
  width: 100%;
  border-collapse: collapse;
}

.rag-results-table th,
.rag-results-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.rag-results-table th {
  background-color: #f6f9fe;
  font-weight: bold;
}

.rag-results-table tr:hover {
  background-color: #f5f5f5;
}

.button-container {
  display: flex;
  justify-content: flex-end; /* Align buttons to the right */
  margin-top: auto;
  margin-bottom: 5px;
}
</style>
