<template>
  <div class="app-container">
    <CHeader class="app-header">
      <CHeaderBrand
        @click="backHome"
        style="
          color: #0c2231;
          font-size: 36px;
          font-family: Pattanakarn;
          cursor: pointer;
        "
      >
        Home
      </CHeaderBrand>
      <CHeaderNav class="d-lg-flex align-items-center">
        <CNavItem
          class="d-none d-lg-block"
          v-for="feature in features"
          :key="feature.route"
        >
          <CNavLink
            :class="{ active: $route.path === feature.route }"
            @click="setActiveFeature(feature.route)"
          >
            {{ feature.title }}
          </CNavLink>
        </CNavItem>
      </CHeaderNav>
    </CHeader>
    <div
      class="app-content"
      :style="{ padding: $route.path === '/poc/search-chat' ? 0 : '20px 0' }"
    >
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeFeature: null, // Track the active feature
      features: [
        { route: '/chatbotmain', title: 'Chatbot Anything' },
        { route: '/poc/main-chat', title: '基本チャット機能' },
        { route: '/poc/search-chat', title: '検索チャット機能' },
        { route: '/poc/summary', title: '要約機能' },
        { route: '/poc/image-generation', title: '画像生成機能' },
        { route: '/poc/meeting-transcription', title: '議事録作成機能' },
        { route: '/poc/ocr', title: 'OCR' },
      ],
    }
  },
  methods: {
    setActiveFeature(route) {
      this.activeFeature = route
      this.$router.push(route) // Push route to navigate
    },
    backHome() {
      this.activeFeature = null
      this.$router.push('/poc')
    },
  },
}
</script>

<style scoped>
.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.app-header {
  flex-shrink: 0;
}

.app-content {
  display: flex;
  align-items: stretch;
  flex-grow: 1;
  overflow: auto;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
}

CHeaderNav {
  flex-grow: 1;
  justify-content: center;
}

CNavLink {
  margin: 0 15px;
  font-size: 18px;
  color: #0c2231;
  cursor: pointer;
}

CNavLink:hover {
  text-decoration: underline;
}

.active {
  font-weight: bold;
}
</style>
