import { createRouter, createWebHistory } from 'vue-router'
import HTTPService from '@/services/HTTPService'
import DefaultLayout from '@/layouts/DefaultLayout'
import StorageService from '../services/StorageService'
import ChangePassword from '@/views/ChangePassword.vue'
import ResetPassword from '@/views/ResetPassword.vue'
import ChatbotDashboard from '@/views/ChatbotDashboard.vue' // import ChatbotDashboard component
import ChatbotStep1 from '@/views/ChatbotStep1.vue' // import ChatbotDashboard component
import ChatbotStep2 from '@/views/ChatbotStep2.vue' // import ChatbotDashboard component
import Chatbot from '@/views/Chatbot.vue'
import ChatbotMain from '@/views/ChatbotMain.vue'
import ChatbotHome from '@/views/ChatbotHome.vue'
import Profile from '@/views/Profile.vue'
import Subscription from '@/views/Subscription.vue'
import Pricing from '@/views/Pricing.vue'
import SubscriptionSuccess from '@/views/SubscriptionSuccess.vue'
import ChatbotHeader from '@/views/ChatbotHeader.vue'
import FAQ from '@/views/FAQ.vue'
import Terms from '@/views/Terms.vue'
import Privacy from '@/views/Privacy.vue'
import Home from '@/views/poc/Home.vue'
import Menu from '@/views/poc/Menu.vue'
import MainChat from '@/views/poc/MainChat.vue'
import SearchChat from '@/views/poc/SearchChat.vue'
import ImageGeneration from '@/views/poc/ImageGeneration.vue'
import Summary from '@/views/poc/Summary.vue'
import MeetingTranscription from '@/views/poc/MeetingTranscription.vue'
import TextCorrection from '@/views/poc/TextCorrection.vue'
import TextTranslation from '@/views/poc/TextTranslation.vue'
import OCR from '@/views/poc/OCR.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/chatbotmain',
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/register/Register.vue'),
  },
  // {
  //   path: '/register/success',
  //   name: 'RegisterSuccess',
  //   component: () => import('@/views/register/RegisterSuccess.vue'),
  // },
  // {
  //   path: '/subscribe/success',
  //   name: 'SubscribeSuccess',
  //   component: () => import('@/views/subscribe/SubscribeSuccess.vue'),
  // },
  // {
  //   path: '/unsubscribe',
  //   name: 'Unsubscribe',
  //   component: () => import('@/views/subscribe/Unsubscribe.vue'),
  // },
  {
    path: '/confirm_registration',
    name: 'ConfirmRegistration',
    component: () => import('@/views/ConfirmRegistration.vue'),
  },
  {
    path: '/:pathMath(.*)*',
    component: () => import('@/views/Page404.vue'),
  },
  // {
  //   path: '/preview',
  //   component: () => import('@/views/history/Preview.vue'),
  // },
  // {
  //   path: '/termandcondition',
  //   component: () => import('@/views/TermAndCondition.vue'),
  // },
  {
    path: '/changepassword',
    name: 'ChangePassword',
    component: ChangePassword,
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
  },
  {
    path: '/chatbot-dashboard', // add a new route for ChatbotDashboard
    name: 'ChatbotDashboard',
    component: ChatbotDashboard,
  },
  {
    path: '/chatbot-step1', // add a new route for ChatbotDashboard
    name: 'ChatbotStep1',
    component: ChatbotStep1,
  },
  {
    path: '/step2/:botType',
    name: 'ChatbotStep2',
    component: ChatbotStep2,
    props: true,
  },
  {
    path: '/chatbot/:bot_id/:conversation_id',
    name: 'Chatbot',
    component: Chatbot,
    props: (route) => ({
      bot_id: route.params.bot_id,
      conversation_id: route.params.conversation_id,
    }),
  },
  {
    path: '/chatbot/:bot_id',
    name: 'Chatbot',
    component: Chatbot,
    props: (route) => ({
      bot_id: route.params.bot_id,
    }),
  },
  {
    path: '/chatbotmain',
    name: 'ChatbotMain',
    component: ChatbotMain,
    props: {
      active: 'ChatbotDashboard',
    },
  },
  {
    path: '/subscription',
    name: 'Subscription',
    component: Subscription,
    props: true,
  },
  {
    path: '/pricing',
    name: 'Pricing',
    component: Pricing,
    props: true,
  },
  {
    path: '/subscription_success',
    name: 'SubscriptionSuccess',
    component: SubscriptionSuccess,
    props: true,
  },
  {
    path: '/chatbothome',
    name: 'ChatbotHome',
    component: ChatbotHome,
    props: true,
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    props: true,
  },
  {
    path: '/chatbotheader',
    name: 'ChatbotHeader',
    component: ChatbotHeader,
    props: true,
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: FAQ,
    props: true,
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms,
    props: true,
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy,
    props: true,
  },
  {
    path: '/files/:filename',
    name: 'OpenFile',
    beforeEnter: (to, from, next) => {
      HTTPService.getfile(to.params.filename)
      // Prevent change URL path
      next(false)
    },
  },
  {
    path: '/poc',
    component: Home,
    children: [
      {
        path: '',
        name: 'Menu',
        component: Menu,
      },
      {
        path: 'main-chat',
        name: 'MainChat',
        component: MainChat,
      },
      {
        path: 'search-chat',
        name: 'SearchChat',
        component: SearchChat,
      },
      {
        path: 'image-generation',
        name: 'ImageGeneration',
        component: ImageGeneration,
      },
      {
        path: 'summary',
        name: 'Summary',
        component: Summary,
      },
      {
        path: 'meeting-transcription',
        name: 'MeetingTranscription',
        component: MeetingTranscription,
      },
      {
        path: 'correction',
        name: 'Correction',
        component: TextCorrection,
      },
      {
        path: 'translation',
        name: 'Translation',
        component: TextTranslation,
      },
      {
        path: 'ocr',
        name: 'OCR',
        component: OCR,
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

export default router

router.beforeEach((to, from, next) => {
  const publicPages = [
    '/login',
    '/register',
    '/reset-password',
    '/register/success',
    '/confirm_registration',
    '/chatbot/:bot_id',
  ]
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = StorageService.getToken()

  if (authRequired && !loggedIn) {
    // return next('/login')
  }

  next()
})
