<template>
  <div class="container-upload">
    <div class="upload-page" v-if="!showResults">
      <div
        class="upload-wrapper"
        @dragover.prevent="handleDragOver"
        @dragleave="handleDragLeave"
        @drop="handleDrop"
        :disabled="isProcessing"
        @click="onEnableUpload"
      >
        <!-- Input file -->
        <input
          type="file"
          hidden
          ref="inputUploadRef"
          @change="handleSelectFile"
          accept=".pdf,.tiff,.jpeg,.jpg,.png"
        />

        <div class="sub-upload-page" v-if="!selectedFile">
          <img class="icon-upload" src="@/assets/icons/upload.svg" />
          <p class="description-file mt-8">
            ここにファイルをドラッグ＆ドロップをして下さい
          </p>
          <p class="description-file mt-8">
            Supports file (PDF, TIFF, JPG, PNG)
          </p>
          <div class="select-file-upload mt-16">
            <button class="note-click" @click.stop="onEnableUpload">
              ファイルを選択
            </button>
          </div>
        </div>

        <div v-else>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            viewBox="0 0 32 32"
            fill="none"
          >
            <path
              d="M18.1147 2.66663C18.8219 2.66678 19.5001 2.94783 20 3.44796L25.8854 9.33329C26.3855 9.83327 26.6666 10.5114 26.6667 11.2186V26.6666C26.6667 27.3739 26.3858 28.0521 25.8857 28.5522C25.3856 29.0523 24.7073 29.3333 24 29.3333H8.00004C7.2928 29.3333 6.61452 29.0523 6.11442 28.5522C5.61433 28.0521 5.33337 27.3739 5.33337 26.6666V5.33329C5.33337 4.62605 5.61433 3.94777 6.11442 3.44767C6.61452 2.94758 7.2928 2.66663 8.00004 2.66663H18.1147ZM16 5.33329H8.00004V26.6666H24V13.3333H18C17.4696 13.3333 16.9609 13.1226 16.5858 12.7475C16.2108 12.3724 16 11.8637 16 11.3333V5.33329ZM18.12 16.876C18.3701 16.6258 18.7092 16.4851 19.0629 16.485C19.4166 16.4849 19.7559 16.6253 20.006 16.8753C20.2562 17.1253 20.3969 17.4645 20.397 17.8182C20.3971 18.1718 20.2567 18.5111 20.0067 18.7613L15.8587 22.9106C15.7225 23.0469 15.5608 23.155 15.3828 23.2287C15.2048 23.3025 15.014 23.3404 14.8214 23.3404C14.6287 23.3404 14.4379 23.3025 14.2599 23.2287C14.082 23.155 13.9202 23.0469 13.784 22.9106L11.9934 21.1186C11.7505 20.8672 11.6161 20.5304 11.6191 20.1808C11.6222 19.8312 11.7624 19.4967 12.0096 19.2495C12.2568 19.0023 12.5912 18.8621 12.9408 18.8591C13.2904 18.856 13.6272 18.9904 13.8787 19.2333L14.8214 20.176L18.12 16.876ZM18.6667 5.88529V10.6666H23.448L18.6667 5.88529Z"
              fill="#09244B"
            />
          </svg>
          <p class="note">{{ selectedFile.name }}</p>
          <p class="cancel-link" @click.stop="clearSelectFile">
            ファイルを削除
          </p>
        </div>
      </div>

      <!-- button submit -->
      <div class="button-container">
        <button
          class="button-submit mt-16"
          :disabled="isProcessing"
          @click="checkOCR"
        >
          チェックする
        </button>
        <span v-if="isProcessing" class="loading-spinner"></span>
      </div>
    </div>

    <!-- display OCR -->
    <div v-if="showResults" class="results-container">
      <div class="results-content">
        <div class="image-container">
          <div class="image-header">
            <h3>原本画像 - Page {{ currentPage }}</h3>
            <button class="back-button" @click="backToUpload">消去</button>
          </div>
          <div class="image-wrapper">
            <canvas ref="canvasRef"></canvas>
            <div v-if="isImageLoading" class="loading-overlay">
              <div class="spinner"></div>
            </div>
          </div>
        </div>

        <div class="text-results-container">
          <div class="text-header">
            <h3>OCR テキスト結果 - Page {{ currentPage }}</h3>
            <button class="download-result-button" @click="downloadResults">
              OCR結果ダウンロード
            </button>
          </div>
          <div class="text-results">
            <div
              v-for="(content, index) in ocrResults.ocr_result[currentPageIndex]
                .content"
              :key="index"
              class="text-item"
              :class="{ 'text-highlighted': highlightedIndex === index }"
              @mouseover="highlightBox(index)"
              @mouseleave="removeHighlight"
            >
              <div class="text-content">
                <strong>{{
                  ocrResults.ocr_result[currentPageIndex].node_type[index]
                }}</strong>
                <div class="content-block">
                  <h4>Original Text</h4>
                  <p>{{ content }}</p>
                </div>
                <div
                  v-if="
                    ocrResults.ocr_result[currentPageIndex].improved_content[
                      index
                    ]
                  "
                  class="content-block"
                >
                  <h4>Improved Text</h4>
                  <p>
                    {{
                      ocrResults.ocr_result[currentPageIndex].improved_content[
                        index
                      ]
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="pagination">
            <button @click="prevPage" :disabled="currentPage === 1">
              &#8249;
            </button>
            <span v-if="currentPage > 2">...</span>
            <button
              v-for="page in visiblePages"
              :key="page"
              :class="{ 'active-page': currentPage === page }"
              @click="setPage(page)"
            >
              {{ page }}
            </button>
            <span v-if="currentPage < ocrResults.ocr_result.length - 1"
              >...</span
            >
            <button
              @click="nextPage"
              :disabled="currentPage === ocrResults.ocr_result.length"
            >
              &#8250;
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HTTPService from '@/services/HTTPService'

export default {
  data() {
    return {
      inputUploadRef: null,
      selectedFile: null,
      showResults: false,
      ocrResults: {
        file_result_excel: '',
        ocr_result: [],
      },
      showEditModal: false,
      editingIndex: -1,
      editingText: '',
      highlightedIndex: -1,
      imageWrapper: null,
      imageObj: new Image(),
      blobUrl: '',
      scale: 1,
      minScale: 0.5,
      maxScale: 2,
      isProcessing: false,
      user: null,
      currentPage: 1,
      isImageLoading: false,
    }
  },

  computed: {
    currentPageIndex() {
      return this.currentPage - 1
    },
    visiblePages() {
      const totalPages = this.ocrResults.ocr_result.length
      if (totalPages <= 5) {
        return Array.from({ length: totalPages }, (_, i) => i + 1)
      }
      if (this.currentPage <= 3) {
        return [1, 2, 3, totalPages - 1, totalPages]
      }
      if (this.currentPage >= totalPages - 2) {
        return [1, totalPages - 3, totalPages - 2, totalPages - 1, totalPages]
      }
      return [
        1,
        this.currentPage - 1,
        this.currentPage,
        this.currentPage + 1,
        totalPages,
      ]
    },
  },

  watch: {
    // currentPage() {
    //   if (
    //     this.ocrResults.ocr_result &&
    //     this.ocrResults.ocr_result[this.currentPageIndex] &&
    //     this.ocrResults.ocr_result[this.currentPageIndex].image_url
    //   ) {
    //     this.loadImage(
    //       this.ocrResults.ocr_result[this.currentPageIndex].image_url,
    //     )
    //   }
    // },
  },

  methods: {
    async loadImageBlob(url) {
      if (!url) {
        console.error('No valid image URL found.')
        return
      }
      try {
        this.imageObj.onload = () => {
          this.drawImage()
        }
        this.imageObj.src = url
      } catch (error) {
        console.error('Error fetching image blob:', error)
      }
    },

    async loadImage(url) {
      if (!url) {
        console.error('No valid image URL found for page', this.currentPage)
        return
      }

      this.isImageLoading = true
      return new Promise((resolve, reject) => {
        this.imageObj = new Image()

        this.imageObj.onload = () => {
          this.isImageLoading = false
          this.drawImage()
          resolve()
        }

        this.imageObj.onerror = (error) => {
          this.isImageLoading = false
          console.error('Error loading image:', error)
          reject(error)
        }

        this.imageObj.src = url
      })
    },

    onEnableUpload() {
      this.$refs.inputUploadRef.click()
    },

    handleSelectFile(event) {
      const file = event.target.files[0]
      if (file) {
        this.selectedFile = file
      }
    },

    clearSelectFile() {
      this.selectedFile = null
      this.$refs.inputUploadRef.value = ''
    },

    handleDragOver(event) {
      event.preventDefault()
    },

    handleDragLeave(event) {
      event.preventDefault()
    },

    handleDrop(event) {
      event.preventDefault()
      const file = event.dataTransfer.files[0]
      if (file) {
        this.selectedFile = file
      }
    },

    async checkOCR() {
      if (!this.selectedFile) {
        alert('Please upload a file first.')
        return
      }

      this.isProcessing = true

      const formData = new FormData()
      formData.append('file', this.selectedFile)
      formData.append('username', this.user.email)

      try {
        const response = await HTTPService.check_ocr(formData)

        console.log('response.data:', response.data)

        if (
          !response.data.ocr_result ||
          !Array.isArray(response.data.ocr_result)
        ) {
          console.error('Invalid OCR result structure:', response.data)
          alert('OCR processing failed. Invalid data structure received.')
          this.isProcessing = false
          return
        }

        this.ocrResults = {
          file_result_excel: response.data.file_result_excel || '',
          ocr_result: response.data.ocr_result,
        }

        console.log('Processed OCR Results:', this.ocrResults)
        this.showResults = true
        this.scale = 1

        if (this.ocrResults.ocr_result.length > 0) {
          this.currentPage = 1
          await this.loadCurrentPageImage()
        }

        if (this.ocrResults.ocr_result.length > 0) {
          this.currentPage = 1
          if (this.ocrResults.ocr_result[0].image_url) {
            await this.loadImage(this.ocrResults.ocr_result[0].image_url)
          } else {
            console.error('No image URL for the first page')
          }
        }
      } catch (error) {
        console.error('Error in OCR Processing:', error)

        if (error.response) {
          console.error('Server Response Error:', error.response.data)
        }
      } finally {
        this.isProcessing = false
      }
    },

    backToUpload() {
      this.showResults = false
      this.selectedFile = null
    },

    drawImage() {
      const canvas = this.$refs.canvasRef
      if (!canvas || !this.imageObj) return

      const ctx = canvas.getContext('2d')
      if (!ctx) return

      // Điều chỉnh kích thước canvas theo scale
      canvas.width = this.imageObj.width * this.scale
      canvas.height = this.imageObj.height * this.scale

      ctx.clearRect(0, 0, canvas.width, canvas.height)
      ctx.drawImage(
        this.imageObj,
        0,
        0,
        this.imageObj.width * this.scale,
        this.imageObj.height * this.scale,
      )

      this.drawBoundingBoxes()
    },

    drawBoundingBoxes() {
      const canvas = this.$refs.canvasRef
      if (!canvas) return

      const ctx = canvas.getContext('2d')
      if (!ctx) return

      if (
        !this.ocrResults.ocr_result ||
        !this.ocrResults.ocr_result[this.currentPageIndex] ||
        !this.ocrResults.ocr_result[this.currentPageIndex].bbox
      ) {
        return
      }

      ctx.strokeStyle = 'red'
      ctx.lineWidth = 2

      this.ocrResults.ocr_result[this.currentPageIndex].bbox.forEach(
        (box, index) => {
          ctx.strokeStyle = this.highlightedIndex === index ? 'blue' : 'red'
          ctx.strokeRect(
            box[0] * this.scale,
            box[1] * this.scale,
            (box[2] - box[0]) * this.scale,
            (box[3] - box[1]) * this.scale,
          )
        },
      )
    },

    handleWheel(event) {
      event.preventDefault()
      const zoomSpeed = 0.1
      const oldScale = this.scale

      if (event.deltaY < 0) {
        // Zoom in
        this.scale = Math.min(this.maxScale, this.scale + zoomSpeed)
      } else {
        // Zoom out
        this.scale = Math.max(this.minScale, this.scale - zoomSpeed)
      }

      if (this.scale !== oldScale && this.imageObj) {
        this.drawImage()
      }
    },

    highlightBox(index) {
      this.highlightedIndex = index
      this.drawBoundingBoxes()
    },
    removeHighlight() {
      this.highlightedIndex = -1
      this.drawBoundingBoxes()
    },
    downloadResults() {
      if (!this.ocrResults.file_result_excel) {
        alert('No downloadable file available.')
        return
      }
      const link = document.createElement('a')
      link.href = this.ocrResults.file_result_excel
      link.setAttribute('download', 'OCR_Result.xlsx')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    async prevPage() {
      if (this.currentPage > 1 && !this.isImageLoading) {
        try {
          this.currentPage--
          await this.loadCurrentPageImage()
        } catch (error) {
          console.error('Error loading previous page:', error)
        }
      }
    },

    async nextPage() {
      if (
        this.currentPage < this.ocrResults.ocr_result.length &&
        !this.isImageLoading
      ) {
        try {
          this.currentPage++
          await this.loadCurrentPageImage()
        } catch (error) {
          console.error('Error loading next page:', error)
        }
      }
    },

    async setPage(page) {
      if (page !== this.currentPage && !this.isImageLoading) {
        try {
          this.currentPage = page
          await this.loadCurrentPageImage()
        } catch (error) {
          console.error('Error loading page:', error)
        }
      }
    },

    async loadCurrentPageImage() {
      if (
        this.ocrResults.ocr_result &&
        this.ocrResults.ocr_result[this.currentPageIndex] &&
        this.ocrResults.ocr_result[this.currentPageIndex].image_url
      ) {
        await this.loadImage(
          this.ocrResults.ocr_result[this.currentPageIndex].image_url,
        )
      } else {
        console.error('No image URL found for page', this.currentPage)
      }
    },
  },

  async mounted() {
    console.log('Component mounted.')
    try {
      console.log('get user..')

      const response = await HTTPService.getuser()
      this.user = response.data.user
    } catch (error) {
      console.error(error)
      this.$emit('change-active-component', {
        component: 'Login',
      })
    }
    const canvas = this.$refs.canvasRef
    if (canvas) {
      canvas.addEventListener('wheel', this.handleWheel, { passive: false })
    }

    console.log('Component mounted.')
    HTTPService.getuser()
      .then((response) => {
        this.user = response.data.user
      })
      .catch((error) => {
        console.error(error)
        this.$emit('change-active-component', {
          component: 'Login',
        })
      })
  },

  beforeUnmount() {
    // Cleanup event listener
    const canvas = this.$refs.canvasRef
    if (canvas) {
      canvas.removeEventListener('wheel', this.handleWheel)
    }
  },
}
</script>
<style scoped>
.container-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.upload-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  width: 100%;
  overflow: auto;
  background-color: #f8f9fa;
}

.app-content::-webkit-scrollbar {
  display: none;
}

.upload-wrapper {
  width: 80%;
  padding: 20px;
  text-align: center;
  background: #fff;
  border: 2px dashed #001d61;
  border-radius: 10px;
  transition: all 0.3s ease;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  min-height: 250px;
}

.upload-wrapper:hover {
  background-color: #f0faff;
}

.sub-upload-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.icon-upload {
  width: 4rem;
  height: 4rem;
}

.description-file {
  margin-top: 10px;
  font-size: 14px;
  color: #666;
}

.description-file:last-of-type {
  font-weight: bold;
  color: #000;
}

.select-file-upload .note-click {
  margin-top: 10px;
  padding: 10px 20px;
  border: 2px solid #001d61;
  background: #fff;
  color: #001d61;
  font-weight: bold;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.select-file-upload .note-click:hover {
  background: #001d61;
  color: #fff;
}

.note {
  margin-top: 10px;
  font-size: 14px;
  font-weight: bold;
}

.cancel-link {
  margin-top: 10px;
  font-size: 14px;
  color: red;
  cursor: pointer;
  text-decoration: underline;
}

.button-container {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
}

.button-submit {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 12px 20px;
  width: 180px;
  background-color: #001d61;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 50px;
}

.button-submit:hover {
  background-color: #0056b3;
}

.button-submit:disabled {
  background-color: #b0bec5;
  cursor: not-allowed;
}

.loading-spinner {
  margin-left: 10px;
  margin-top: 50px;
  width: 18px;
  height: 18px;
  border: 3px solid rgba(211, 33, 33, 0.3);
  border-top: 3px solid #fff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.image-header,
.text-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.back-button,
.download-result-button {
  padding: 8px 12px;
  border: none;
  background-color: #001d61;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.back-button:hover,
.download-result-button:hover {
  background-color: #0056b3;
}

.results-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}

.results-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
  position: sticky;
  top: 0;
  background: white;
  z-index: 100;
  padding: 10px;
}

.results-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 20px;
  flex-grow: 1;
  overflow: hidden;
}

.image-container {
  flex: 0 0 65%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  background-color: white;
}

.image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
  scroll-behavior: smooth;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

canvas {
  width: 100%;
  height: auto;
  max-height: 80vh;
  background: white;
}

.text-results-container {
  flex: 0 0 35%;
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px 40px 15px 15px;
  background-color: white;
  overflow-y: auto;
  max-height: 90vh;
}

.text-results {
  overflow-y: auto;
  height: 100%;
}

.text-item {
  padding: 10px;
  border: 1px solid #eee;
  margin-bottom: 10px;
  transition: background-color 0.2s;
}

.text-item:hover {
  background-color: rgba(0, 123, 255, 0.1);
}

.text-highlighted {
  background-color: rgba(0, 123, 255, 0.3);
  border-left: 4px solid #007bff;
}

.content-block {
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background: #f9f9f9;
}

.content-block h4 {
  margin: 0 0 5px 0;
  font-size: 14px;
  font-weight: bold;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 5px;
}

.pagination button {
  padding: 8px 12px;
  border: none;
  background-color: white;
  color: #001d61;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  border: 1px solid #001d61;
}

.pagination button:hover {
  background-color: #e0e7ff;
}

.pagination .active-page {
  background-color: #d6d9ff;
  border-color: #5a67d8;
  color: #5a67d8;
}

.pagination span {
  padding: 8px 12px;
  color: #888;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (min-width: 1400px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1800px;
  }
}
</style>
