<template>
  <div class="chatbot">
    <h1>{{ name }}</h1>
    <div class="chatbot-container">
      <div class="chatbot-header" :style="getHeadingStyle">
        <div v-if="getImage" class="image-container">
          <span>
            <img :src="getImage" alt="chatbot-image" class="centered-image" />
          </span>
        </div>

        <div>
          <h2 class="chatbot-heading">
            {{ getHeading }}
          </h2>
          <p>{{ getSubHeading }}</p>
        </div>
      </div>

      <div class="model-selector">
        <select v-model="selectedModel" @change="onModelChange">
          <option value="gpt-4o">ChatGPT-4o</option>
          <option value="gpt-4o-mini">ChatGPT-4o mini</option>
          <option value="claude-3-5-sonnet-20240620">Claude 3.5 Sonnet</option>
          <option value="claude-3-7-sonnet-20250219">Claude 3.7 Sonnet</option>
        </select>
      </div>

      <div class="chatbot-conversation" ref="chatbotConversation">
        <div
          class="chatbot-message"
          v-for="(message, index) in messages"
          :key="index"
          :class="{
            'user-message': message.is_user,
            'bot-message': !message.is_user,
          }"
        >
          <div v-if="message.is_user" :style="getUserMessageStyle">
            <strong>You:</strong> {{ message.text }}
          </div>

          <!-- <div
            v-else
            :style="getBotMessageStyle"
            class="prose prose-sm prose-chatgpt break-words max-w-6xl"
            v-html="parseMarkdown(message.text)"
          /> -->
          <div
            class="rag-results-table-top5"
            v-if="
              message.is_result_top &&
              message.ragResultTop5 &&
              message.ragResultTop5.length > 0
            "
          >
            <table>
              <thead>
                <tr>
                  <th>Document Name</th>
                  <th>RAG Result Text</th>
                  <th>Score</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(res, idx) in message.ragResultTop5" :key="idx">
                  <td>
                    {{ res.source }}
                    <span>
                      (<a
                        href="#"
                        @click.prevent="
                          downloadFile(getSourceFilename(res.source, res.page))
                        "
                        ><span v-if="res.source.endsWith('pdf')"
                          >Page {{ +res.page + 1 }}</span
                        ><span v-if="!res.source.endsWith('pdf')">Link</span></a
                      >)
                    </span>
                  </td>
                  <td>{{ res.content }}</td>
                  <td>{{ res.score.toFixed(2) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            v-if="!message.is_user"
            :style="getBotMessageStyle"
            v-html="
              '<strong>' +
              getAssistantName +
              ':</strong>' +
              parseMarkdown(message.text)
            "
          ></div>
        </div>
      </div>
      <div class="chatbot-input" ref="chatbotInput">
        <textarea
          type="text"
          v-model="userInput"
          @keydown.enter="sendMessage"
          :placeholder="getInputMessage"
          @input="autoResize"
        ></textarea>
        <CButton
          @click="sendMessage"
          class="d-flex justify-content-center"
          :style="getSendButtonStyle"
        >
          <CIcon :icon="cilSend" />
        </CButton>
      </div>
    </div>
    <div class="rag-results-table" v-if="ragResult && ragResult.length > 0">
      <h3>RAG Results</h3>
      <table>
        <thead>
          <tr>
            <th>Document Name</th>
            <th>RAG Result Text</th>
            <th>Score</th>
            <th
              v-if="ragResult.some((item) => item.rerank_score !== undefined)"
            >
              Rerank Score
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(result, index) in ragResult" :key="index">
            <td>
              {{ result.source }}
              <span>
                (<a
                  href="#"
                  @click.prevent="
                    downloadFile(getSourceFilename(result.source, result.page))
                  "
                  ><span v-if="result.source.endsWith('pdf')"
                    >Page {{ +result.page + 1 }}</span
                  ><span v-if="!result.source.endsWith('pdf')">Link</span></a
                >)
              </span>
            </td>
            <td>{{ result.content }}</td>
            <td>{{ result.score.toFixed(2) }}</td>
            <td v-if="result.rerank_score !== undefined">
              {{ result.rerank_score.toFixed(4) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import HTTPService from '@/services/HTTPService.js'
import { v4 as uuidv4 } from 'uuid'
import { cilSend } from '@coreui/icons'
import { marked } from 'marked'
import DOMPurify from 'isomorphic-dompurify'
// import hljs from 'highlight.js'

export default {
  props: {
    bot_id: {
      type: String,
      required: true,
    },
    conversation_id: {
      type: String,
      required: false,
    },
    style: {
      type: Object,
      default: () => ({
        botBackgroundColor: '#dcdcdc',
        botTextColor: 'black',
        chatbotHeading: 'Hello!',
        chatbotSubHeading: 'Ask me anything',
        firstMessage: 'Hello, what can I help you with?',
        headerBackgroundColor: '#0c2231',
        headerTextColor: '#FFFFFF',
        image: null,
        inputPlaceholderText: 'enter your question here..',
        sendButtonBackgroundColor: '#0c2231',
        userBackgroundColor: '#0c2231',
        userTextColor: '#FFFFFF',
        assistantName: 'Assistant',
      }),
    },
    name: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      messages: [],
      userInput: '',
      messageIndex: 0,
      conversationId: '',
      cilSend,
      localstyle: {},
      user: null,
      ragResult: [],
      selectedModel: 'gpt-4o', // Default model
      model_completion: '',
      top5Results: [],
    }
  },
  watch: {
    model_completion(newValue) {
      this.selectedModel = newValue !== '' ? newValue : 'gpt-4o'
    },
  },
  async mounted() {
    console.log('loading..' + this.bot_id)
    try {
      console.log('get user..')

      const response = await HTTPService.getuser()
      this.user = response.data.user
    } catch (error) {
      console.error(error)
      this.$emit('change-active-component', {
        component: 'Login',
      })
    }

    marked.setOptions({
      silent: true,
      xhtml: true,
      breaks: true,
      gfm: true,
    })

    // const renderer = {
    //   code(code, lang) {
    //     let language = 'plaintext'
    //     let highlightedCode
    //     try {
    //       highlightedCode = hljs.highlightAuto(code).value
    //     } catch {
    //       language = hljs.getLanguage(lang) ? lang : 'plaintext'
    //       highlightedCode = hljs.highlight(code, { language }).value
    //     }

    //     // ... handle the highlighted code as needed

    //     // For example, you can append it to an element or return it as a string
    //     // For appending to an element:
    //     const container = document.createElement('div')
    //     container.innerHTML = highlightedCode
    //     document.body.appendChild(container)

    //     // For returning as a string:
    //     return highlightedCode
    //   },
    // }

    // marked.use({ renderer })

    if (this.bot_id !== 'preview') {
      try {
        const response = await HTTPService.get_style(this.bot_id)
        // console.log('finished loading bot settings')
        // console.log(response)
        this.localstyle = response.data.style
        this.model_completion = response.data.chatbot.model_completion
      } catch (error) {
        console.error(error)
        // if there is an error default
        this.localstyle = this.style
      }
    }

    if (this.bot_id === 'preview') {
      this.messages.push({
        text: this.style.firstMessage,
        is_user: false,
      })
      this.messageIndex = 1

      this.messages.push({
        text: 'what is this',
        is_user: true,
      })
      this.messageIndex = 2
    } else {
      this.messages.push({
        text: this.localstyle.firstMessage,
        is_user: false,
        is_result_top: false,
        top5Result: [],
      })
      this.messageIndex = 1
    }
    if (this.conversation_id != '') {
      this.conversationId = this.conversation_id
    }

    if (this.$route.query.conversationId) {
      this.conversationId = this.$route.query.conversationId
    }

    if (!this.conversationId) {
      this.conversationId = uuidv4()
    } else {
      this.startConversation()
    }

    this.selectedModel = this.modelCompletionSelection
  },
  computed: {
    getImage() {
      if (this.bot_id == 'preview') {
        return this.style.image
      } else {
        return this.localstyle.image
      }
    },

    getAssistantName() {
      if (this.bot_id == 'preview') {
        return this.style.assistantName
      } else {
        return this.localstyle.assistantName
      }
    },
    getHeading() {
      if (this.bot_id == 'preview') {
        return this.style.chatbotHeading
      } else {
        return this.localstyle.chatbotHeading
      }
    },
    getSubHeading() {
      if (this.bot_id == 'preview') {
        return this.style.chatbotSubHeading
      } else {
        return this.localstyle.chatbotSubHeading
      }
    },
    getHeadingStyle() {
      // console.log(
      //   'this is headerBackgroundColor' + this.style.headerBackgroundColor,
      // )
      if (this.bot_id == 'preview') {
        return {
          backgroundColor: this.style.headerBackgroundColor,
          color: this.style.headerTextColor,
        }
      } else {
        return {
          backgroundColor: this.localstyle.headerBackgroundColor,
          color: this.localstyle.headerTextColor,
        }
      }
    },
    getSendButtonStyle() {
      if (this.bot_id == 'preview') {
        return {
          backgroundColor: this.style.sendButtonBackgroundColor,
        }
      } else {
        return {
          backgroundColor: this.localstyle.sendButtonBackgroundColor,
        }
      }
    },
    getInputMessage() {
      if (this.bot_id == 'preview') {
        return this.style.inputPlaceholderText
      } else {
        return this.localstyle.inputPlaceholderText
      }
    },
    getUserMessageStyle() {
      // console.log('this is getUserMessageStyle' + this.style.userTextColor)
      if (this.bot_id == 'preview') {
        return {
          backgroundColor: this.style.userBackgroundColor,
          color: this.style.userTextColor,
        }
      } else {
        return {
          backgroundColor: this.localstyle.userBackgroundColor,
          color: this.localstyle.userTextColor,
        }
      }
    },
    getBotMessageStyle() {
      // console.log('this is getBotMessageStyle' + this.style.botTextColor)

      if (this.bot_id == 'preview') {
        return {
          backgroundColor: this.style.botBackgroundColor,
          color: this.style.botTextColor,
        }
      } else {
        return {
          backgroundColor: this.localstyle.botBackgroundColor,
          color: this.localstyle.botTextColor,
        }
      }
    },
    onModelChange() {
      console.log('Selected model:', this.selectedModel)
      return null
    },
    modelCompletionSelection() {
      return this.model_completion !== '' ? this.model_completion : 'gpt-4o'
    },
  },
  methods: {
    downloadFile(filename) {
      HTTPService.getfile(filename)
    },
    parseMarkdown(text) {
      if (typeof text !== 'string') {
        return '' // Return the text as is if it's not a string
      }

      text = text.replaceAll('\\n', '\n')
      text = text.replaceAll('```markdown', '```')
      try {
        const renderer = new marked.Renderer()
        renderer.link = function (href, title, text) {
          // Renderer() seems to return different parameters depending on the version of marked installed...
          const link = {
            href: href.href ?? href,
            text: href.text ?? text,
          }
          if (link.href.startsWith('/files/')) {
            // Extract the filename from the path (e.g., "/files/<filename>")
            const filename = link.href.split('/').pop()
            return `<a href="#" @click.prevent="downloadFile(${filename})">${link.text}</a>`
          } else {
            return `<a target="_blank" href="${link.href}">${link.text}</a>`
          }
        }

        text = text.replaceAll('```', '')
        let parsed = marked(text, { renderer: renderer })
        console.log('after marked.parse: ', text)

        // format Bing's source links more nicely
        // 1. replace "[^1^]" with "[1]" (during progress streams)
        parsed = parsed.replace(/\[\^(\d+)\^]/g, '<strong>[$1]</strong>')
        // 2. replace "^1^" with "[1]" (after the progress stream is done)
        parsed = parsed.replace(/\^(\d+)\^/g, '<strong>[$1]</strong>')
        console.log('after parsed: ', parsed)

        // Allow the iframe to show the images created by Bing Image Creator.
        return DOMPurify.sanitize(parsed, {
          ADD_TAGS: ['iframe'],
          ADD_ATTR: [
            'allow',
            'allowfullscreen',
            'frameborder',
            'scrolling',
            'srcdoc',
            'target',
          ],
        })
      } catch (err) {
        console.error('ERROR', err)
        return null
      }
    },
    async startConversation() {
      try {
        const response = await HTTPService.get_conversation(this.conversationId)
        // console.log(response.data)
        for (const message of response.data) {
          const text = message.text
          const is_user = message.is_user
          const is_result_top = false
          const ragResultTop5 = []
          this.messages.push({ text, is_user, is_result_top, ragResultTop5 })
          // this.addMessage(message.text, message.is_user)
        }
      } catch (error) {
        console.log(error)
      }
    },

    async sendMessage() {
      if (!this.userInput) return
      this.addMessage(this.userInput, true, false, [])
      this.messageIndex = this.messageIndex + 1
      console.log(this.user.email)
      // URL encode user input and username to ensure they are safe to include in the URL
      // const encodedUserInput = encodeURIComponent(this.userInput)
      // const encodedUsername = encodeURIComponent(this.user.email)

      const url = `${process.env.VUE_APP_API_URL}/conversation`

      const payload = {
        bot_id: this.bot_id,
        user_input: this.userInput,
        conversation_id: this.conversationId,
        username: this.user.email,
        showRagResults: true,
        model: this.selectedModel,
        isChatbotAnything: true,
      }

      this.userInput = ''
      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'multipart/mixed',
          },
          body: JSON.stringify(payload),
        })

        if (!response.ok) {
          const errorBody = await response.json()
          this.errorMessage =
            errorBody.error ||
            `HTTP error! status: ${response.status} ${response.statusText}`
          this.showErrorPopup = true
          throw new Error(this.errorMessage)
        }

        const reader = response.body.getReader()
        const decoder = new TextDecoder()

        let buffer = ''
        let isProcessingJson = true

        // eslint-disable-next-line no-constant-condition
        while (true) {
          const { done, value } = await reader.read()
          if (done) break

          buffer += decoder.decode(value, { stream: true })

          if (isProcessingJson) {
            const jsonEndIndex = buffer.indexOf('\r\n--frame\r\n')
            if (jsonEndIndex !== -1) {
              const jsonPart = buffer.slice(0, jsonEndIndex)
              const match = jsonPart.match(/\{[\s\S]*\}/)
              if (match) {
                this.ragResult = JSON.parse(match[0]).vdb_results
                this.top5Results = this.ragResult.slice(0, 5) //get top5
                // Initialize the message
                this.addMessage('', false, true, this.top5Results)
              }
              buffer = buffer.slice(jsonEndIndex + 8) // 8 is the length of '\r\n--frame'
              isProcessingJson = false
            }
          } else {
            const lines = buffer.split('\n')
            buffer = lines.pop() || '' // Keep the last incomplete line in the buffer

            for (const line of lines) {
              if (line.startsWith('data:')) {
                const data = line.slice(6)
                if (data === '[DONE]') {
                  this.messageIndex = this.messageIndex + 1
                  return // End of stream
                } else {
                  this.addMessage(data, false, true, this.top5Results)
                }
              }
            }
          }
        }
      } catch (error) {
        console.error(error)
        if (error.response && error.response.data.error) {
          this.errorMessage = error.response.data.error
          this.showErrorPopup = true
        } else {
          this.errorMessage = 'Failed to create chatbot: ' + error.response
          this.showErrorPopup = true
        }
      }
    },
    addMessage(text, is_user, is_result_top, ragResultTop5) {
      if (this.messageIndex >= 0 && is_user === false) {
        // if first message then just push
        if (!this.messages[this.messageIndex]) {
          this.messages.push({
            text: '',
            is_user: is_user,
            is_result_top: is_result_top,
            ragResultTop5: ragResultTop5,
          })
        } else {
          // console.log('adding "' + text + '"')

          this.messages[this.messageIndex].text += text
          this.messages[this.messageIndex].is_user = is_user
          this.messages[this.messageIndex].is_result_top = is_result_top
          this.messages[this.messageIndex].ragResultTop5 = ragResultTop5
        }
      } else {
        this.messageIndex = this.messages.length

        this.messages.push({
          text,
          is_user,
          is_result_top,
          ragResultTop5,
        })
        this.messageIndex = this.messages.length - 1
      }

      // Scroll to the bottom of the chatbot conversation
      this.$refs.chatbotConversation.scrollTop =
        this.$refs.chatbotConversation.scrollHeight
    },
    getSourceFilename(source, page) {
      const parts = source.split('/')
      const filenameWithExtension = parts[parts.length - 1]
      if (!source.endsWith('pdf')) {
        return filenameWithExtension
      }
      const dotIndex = filenameWithExtension.lastIndexOf('.')
      const filename = filenameWithExtension.slice(0, dotIndex)
      return `${filename}_page${+page + 1}.jpg`
    },
    endConversation() {
      // this.$router.push('/chatbot-dashboard')
    },
    autoResize() {
      const textarea = this.$refs.chatbotInput.querySelector('textarea')
      textarea.style.height = 'auto'
      textarea.style.height = textarea.scrollHeight + 'px'
    },
    handleInput() {
      const maxChars = 7000
      if (this.userInput.length > maxChars) {
        this.userInput = this.userInput.slice(0, maxChars)
      }

      this.autoResize()
    },
  },
}
</script>

<style scoped>
.chatbot {
  max-width: 1200px;
  margin: 0 auto;
}

h1 {
  text-align: center;
}

.chatbot-container {
  display: flex;
  flex-direction: column;
  height: 700px;
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
}

.chatbot-conversation {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
  /* overflow: auto; */
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.chatbot-message {
  margin: 10px 0;
}

.user-message {
  text-align: right;
}

.bot-message {
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.chatbot-message div {
  display: inline-block;
  padding: 1.25rem;
  border-radius: 5px;
  max-width: 80%;
  width: fit-content;
}

.chatbot-message div:last-child {
  margin-bottom: 0;
}

.chatbot-message.user-message div {
  color: #fff;
}

.chatbot-message.bot-message div {
  color: #fff;
}

.chatbot-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
}

textarea {
  flex: 1;
  margin-right: 10px;
  padding: 10px 15px;
  border-radius: 5px;
  border: 1px solid #ccc;
  line-height: normal;
  resize: vertical;
  min-height: 50px;
  max-height: 150px;
  overflow-y: auto;
}

button {
  padding: 12px 17px;
  border-radius: 5px;
  border: none;
  color: #fff;
}

button:hover {
  cursor: pointer;
  background-color: #0089b9;
}

.chatbot-header {
  display: flex;
  flex-direction: row;
  background-color: rgb(173, 92, 92);
  width: 100%;
  /* height: 70px; */
  padding-top: 10px;
  padding-bottom: 10px;
  padding-inline: 10px;
  font-size: 1.25rem;
  margin-bottom: 0px;
}

.chatbot-heading {
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.2;
}

p {
  margin-bottom: 0px;
  font-size: 16px;
}
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
.centered-image {
  max-width: 40px;
  max-height: 40px;
}
.rag-results-table {
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.rag-results-table h3 {
  margin-bottom: 10px;
}
.rag-results-table table {
  width: 100%;
  border-collapse: collapse;
}
.rag-results-table th,
.rag-results-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}
.rag-results-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}
.rag-results-table tr:nth-child(even) {
  background-color: #f9f9f9;
}
.rag-results-table tr:hover {
  background-color: #f5f5f5;
}

.model-selector {
  position: sticky;
  top: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  padding: 10px 0;
  background-color: none; /* Or any color that matches your design */
}

.model-selector select {
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: white;
  font-size: 14px;
}

.rag-results-table-top5 {
  display: block;
  color: black;
  max-height: 300px;
  overflow-y: auto;
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 !important;
}

.rag-results-table-top5 table {
  width: 100%;
  border-collapse: collapse;
}

.rag-results-table-top5 th,
.rag-results-table-top5 td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  color: black;
}

.rag-results-table-top5 th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.rag-results-table-top5 tr:nth-child(even) {
  background-color: #fffefe;
}

.rag-results-table-top5 tr:hover {
  background-color: #f5f5f5;
}
</style>
