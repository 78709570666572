import axios from 'axios'
import StorageService from './StorageService'
import router from '../router'

class HTTPService {
  http_common() {
    const baseDomain = process.env.VUE_APP_API_URL
    return axios.create({
      baseURL: baseDomain,
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: false, // Set withCredentials to true
    })
  }

  // New method to handle POST request with form data
  async createChatbot(formData) {
    let token = StorageService.getToken()
    let http = this.http_common()
    return await http.post('/create-chatbot', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    })
  }

  conversation(text, bot_id) {
    const token = StorageService.getToken()
    const http = this.http_common()
    console.log(token)
    return http.post(
      '/conversation',
      {
        text,
        bot_id,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    )
  }
  gethistory() {
    let token = StorageService.getToken()
    let http = this.http_common()
    return http.get('/history', {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    })
  }

  get_chatbots() {
    let token = StorageService.getToken()
    let http = this.http_common()
    return http
      .get('/chatbots', {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  update_chatbot_order(orderData) {
    let token = StorageService.getToken()
    let http = this.http_common()
    return http
      .post('/chatbots/update_order', orderData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  gethistoryadmin() {
    let token = StorageService.getToken()
    let http = this.http_common()
    return http.get('/adminhistory', {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    })
  }

  // changePassword(password, newPassword) {
  //   let token = StorageService.getToken()
  //   let http = this.http_common()
  //   return http.post('/change_password', {
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: `Bearer ${token}`,
  //     },
  //     params: {
  //       password: password,
  //       new_password: newPassword,
  //     },
  //   })
  // }

  changePassword(password, newPassword) {
    const token = StorageService.getToken()
    const http = this.http_common()
    return http.post(
      '/change_password',
      {
        new_password: newPassword,
        old_password: password,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    )
  }

  getuser() {
    let token = StorageService.getToken()
    let http = this.http_common()

    return http
      .get('/get_user', {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
      .catch((error) => {
        console.log('error2')
        return Promise.reject(error)
      })
  }

  getfile(filename) {
    let token = StorageService.getToken()
    let http = this.http_common()

    http
      .get('/files/' + filename, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: '0',
        },
        responseType: 'blob',
      })
      .then((response) => {
        const contentType = response.headers['content-type']
        const blob = new Blob([response.data], { type: contentType })
        const url = window.URL.createObjectURL(blob)

        // Trigger download without opening a new tab
        const link = document.createElement('a')
        link.href = url
        link.download = filename
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

        window.URL.revokeObjectURL(url)
      })
      .catch((error) => {
        console.log('getfile error')
        return Promise.reject(error)
      })
  }

  getplan(plan_id) {
    let token = StorageService.getToken()
    let http = this.http_common()
    if (!token) {
      //console.log('no token')
      router.push('/chatbotmain')
    }
    //console.log('test2')
    //console.log(plan_id)
    return http.post(
      `/get_plan`,
      {
        plan_id: plan_id,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    )
  }

  subscribe(body) {
    let token = StorageService.getToken()
    let http = this.http_common()
    return http.post('/subscribe', body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
  }
  unsubscribe() {
    let token = StorageService.getToken()
    let http = this.http_common()
    return http.post(
      '/unsubscribe',
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    )
  }
  canceluser() {
    let token = StorageService.getToken()
    let http = this.http_common()
    return http.post(
      '/cancel_user',
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    )
  }
  deletedetection(detection_id) {
    let token = StorageService.getToken()
    let http = this.http_common()
    return http.post(
      '/delete_detection',
      { detection_id: detection_id },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    )
  }
  gethistoryresult(detection_id) {
    let token = StorageService.getToken()
    let http = this.http_common()
    return http.get('/detection_history', {
      params: { detection_id: parseInt(detection_id) },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  }
  login(email, password) {
    let http = this.http_common()
    return http.post('/login_user', {
      email: email,
      password: password,
    })
  }

  resetPassword(emailAddress) {
    let http = this.http_common()
    return http.post(`/reset_password`, {
      email: emailAddress,
    })
  }

  register(firstname, lastname, email, password) {
    let http = this.http_common()
    return http.post('/register_user', {
      firstname: firstname,
      lastname: lastname,
      email: email,
      password: password,
    })
  }
  upload(file, use_free_server = null) {
    let http = this.http_common()
    let token = StorageService.getToken()
    let formData = new FormData()
    formData.append('file', file)
    formData.append(
      'data',
      JSON.stringify({ use_free_server: use_free_server }),
    )
    return http.post('/upload_detect', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    })
  }
  sendconfirmemail(body) {
    let http = this.http_common()
    return http.post('/send_confirm_email', body)
  }
  confirmRegistration(token) {
    let http = this.http_common()
    return http.post(`/confirm_registration/${token}`)
  }
  upgradeEnterprise(body) {
    let token = StorageService.getToken()
    let http = this.http_common()
    return http.post('/upgrade_enterprise', body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
  }
  upgradeStandard(body) {
    let token = StorageService.getToken()
    let http = this.http_common()
    return http.post('/upgrade_standard', body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
  }
  subscribesuccess(success_id1) {
    let token = StorageService.getToken()
    let http = this.http_common()
    // return http.post(`/subscribe_success?success_id=${success_id}`, null, {
    //   headers: {
    //     'Content-Type': 'application/json',
    //     Authorization: `Bearer ${token}`,
    //   },
    // })
    return http.post(
      `/subscribe_success`,
      {
        success_id: success_id1,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    )
  }

  update_chatbot(editingChatbot) {
    let token = StorageService.getToken()
    let http = this.http_common()
    return http.post(
      `/update_chatbot`,
      {
        chatbot: editingChatbot,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    )
  }

  get_conversation_history(bot_id) {
    let token = StorageService.getToken()
    let http = this.http_common()
    return http.get(`/get_conversation_history?bot_id=${bot_id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
  }

  get_conversation(conversation_id) {
    let token = StorageService.getToken()
    let http = this.http_common()
    // return http.get(`/get_conversation?conversation_id=${conversation_id}`)
    console.log(token)
    return http.get(`/get_conversation?conversation_id=${conversation_id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
  }

  get_style(bot_id) {
    let http = this.http_common()
    return http.get(`/get_style?bot_id=${bot_id}`)
  }

  get_url(url, max_level_get_url) {
    // ////console.log(url)
    let token = StorageService.getToken()
    let http = this.http_common()
    return http.post(
      `/get_urls`,
      {
        url: url,
        max_level_get_url: max_level_get_url,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    )
  }

  async generate_image(payload) {
    let http = this.http_common()
    let token = StorageService.getToken()
    return http.post('/generate-image', payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  }

  async transcribe_audio(formData) {
    let http = this.http_common()
    return await http.post('/transcribe-audio', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  async check_ocr(formData) {
    let http = this.http_common()
    return await http.post('/ocr', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  async delete_file(data) {
    let http = this.http_common()
    let token = StorageService.getToken()
    return await http.post('/delete-chatbot-file', data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  }

  async delete_chatbot(data) {
    let http = this.http_common()
    let token = StorageService.getToken()
    return await http.post('/delete-chatbot', data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  }
}
const FREEPLANS = [
  {
    id: 'free',
    name: 'Free',
    description: 'Free Plan',
    price: '$0',
    chatbots: 1,
    messages: '5 messages / day',
    characters: '1,000,000 characters (~1MB) / chatbot',
    features: ['Install chatbot on website'],
  },
]
const CUSTOMPLANS = [
  {
    id: 'custom',
    name: 'Custom',
    description: 'Custom Self Hosted',
    price: '$$$',
    chatbots: 'Unlimited',
    messages: 'Unlimited',
    characters: 'Unlimited',
    features: [
      'Onsite Company Self Hosted',
      'Data Privacy',
      'Install chatbot on website',
      'API access',
    ],
  },
]
const PLANS = [
  {
    id: 'free',
    name: 'Free',
    description: 'Free Plan',
    price: '$0',
    chatbots: 1,
    messages: '5 messages / day',
    characters: '1,000,000 characters (~1MB) / chatbot',
    features: ['Install chatbot on website'],
  },
  {
    id: 'standard',
    name: 'Standard',
    description: 'Chatbot AI Standard Plan',
    price: '$15',
    chatbots: 15,
    messages: '600 messages / month',
    characters: '3,000,000 characters (~3MB) / chatbot',
    features: ['Install chatbot on website', 'API access'],
  },
  {
    id: 'pro',
    name: 'Pro',
    price: '$50',
    description: 'Chatbot AI Pro Plan',
    chatbots: 25,
    messages: '3,000 messages / month',
    characters: '5,000,000 characters (~5MB) / chatbot',
    features: ['Install chatbot on website', 'API access'],
  },
  {
    id: 'enterprise',
    name: 'Enterprise',
    description: 'Chatbot AI Enterprise Plan',
    price: '$250',
    chatbots: 50,
    messages: '250,000 messages / month',
    characters: '10,000,000 characters (~10MB) / chatbot',
    features: ['Install chatbot on website', 'API access'],
  },
  {
    id: 'custom',
    name: 'Custom',
    description: 'Custom Self Hosted',
    price: '$$$',
    chatbots: 'Unlimited',
    messages: 'Unlimited',
    characters: 'Unlimited',
    features: [
      'Onsite Company Self Hosted',
      'Data Privacy',
      'Install chatbot on website',
      'API access',
    ],
  },
]

export { PLANS, CUSTOMPLANS, FREEPLANS }

export default new HTTPService()
